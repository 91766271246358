<template>
    <div class="py-10 mb-10 container">
        <div class="col-md-12"><h2 class="text-center text-blue" style="font-size: 40px; margin: 30px 0px;">BUS AMENITIES</h2></div>
        <div class="col-md-12">
            <div class="row">
            <div v-for="(baData,i) in busAmenitiesData" :key="i" @mouseenter="toggleShowTitle(i,true)" @mouseleave="toggleShowTitle(i,false)"
                 style="padding: 20px 10px 30px 10px;"
                 class="col-md-2 col-4 text-center bus-amenities"
                 v-bind:class="[ i%2==0 && i<6 ? 'even' : 'odd' , i%2==1 && i>=6 ? 'even' : 'odd' ]" >
                <img v-lazy="baData.image_url" :alt="baData.title" width="80" height="80">
                <div class="bg-aqua bus-amenities-name" v-show="baData.showTitle" style="padding: 5px; margin: 0px -10px -30px -10px;">{{baData.title}}</div>
            </div>
        </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'BusAmenities',
        data(){
            return{
                busAmenitiesData : [
                    { 'showTitle':false, 'title': 'Water Bottle', 'image_url': 'media/icons/water.png' },
                    { 'showTitle':false, 'title': 'Blanket', 'image_url': 'media/icons/blanket.png' },
                    { 'showTitle':false, 'title': 'TV', 'image_url': 'media/icons/tv.png' },
                    { 'showTitle':false, 'title': 'Bus Tracking', 'image_url': 'media/icons/tracking.png' },
                    { 'showTitle':false, 'title': 'Charging Port', 'image_url': 'media/icons/charger.png' },
                    { 'showTitle':false, 'title': 'Snacks', 'image_url': 'media/icons/snacks.png' },
                    { 'showTitle':false, 'title': 'Emergency Exit', 'image_url': 'media/icons/emergency-exit.png' },
                    { 'showTitle':false, 'title': 'Air Conditioner', 'image_url': 'media/icons/ac.png' },
                    { 'showTitle':false, 'title': 'Fire Extinguisher', 'image_url': 'media/icons/fire.png' },
                    { 'showTitle':false, 'title': 'Pillow', 'image_url': 'media/icons/pillow.png' },
                    { 'showTitle':false, 'title': 'Reading Light', 'image_url': 'media/icons/reading-book.png' },
                    { 'showTitle':false, 'title': 'Wifi', 'image_url': 'media/icons/wifi.png' },
                ]
            }
        },
        methods:{
            toggleShowTitle(i,value){
                this.busAmenitiesData[i].showTitle = value;
            }
        },
    }
</script>

<style scoped>
.odd {
    background:#f5f5f5;
}
.even{
    background:#ffffff;
}
</style>
